




























import { Component, Provide, Vue } from 'vue-property-decorator'
import { ProjectViewModel } from '../viewmodel/project-viewmodel'
@Component({
  components: {
    BannerProject: () => import('../components/banner-project.vue'),
    ActiveProject: () => import('../components/active-project.vue'),
    UpcomingProject: () => import('../components/upcoming-project.vue'),
    FundedProject: () => import('../components/funded-project-table.vue'),
  },
})
export default class ProjectPage extends Vue {
  @Provide() vm = new ProjectViewModel()
}
