import { observable, action, computed, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { PoolStore } from '@/stores/pool-store'
import { poolsStore } from '@/stores/pools-store'
import { sortBy, toNumber } from 'lodash-es'
import moment from 'moment'

export class ProjectViewModel {
  @observable loading = false

  @observable activeProjects: PoolStore[] = []
  @observable upcomingProjects: PoolStore[] = []

  //funded project
  @observable fundedProjects: PoolStore[] = []

  @observable numberItemDisplay = 5
  @observable currentPage = 1
  @observable mostRecentFilter = false
  @observable totalRaisedFilter = false
  @observable textSearch = ''

  @observable bannerProjects = {
    currentPrice: 0,
    marketCap: 0,
    raised: 0,
    IGOPrice: 0,
    IGOROI: 0,
  }

  constructor() {
    this.fetchPools()
  }

  @asyncAction *fetchPools() {
    try {
      this.loading = true
      yield poolsStore.fetchPools()
      this.upcomingProjects = poolsStore.validPools.filter(
        (p: PoolStore) => !p?.pool?.status || p?.pool?.status === 'upcoming'
      )
      this.activeProjects = poolsStore.validPools.filter((p: PoolStore) => p?.pool?.status === 'active')
      this.fundedProjects = poolsStore.validPools.filter((p: PoolStore) => p?.pool?.status === 'funded')
    } catch (error) {
      //
    } finally {
      this.loading = false
    }
  }

  /**
   * Funded Project Display
   *
   * slicedFundedProject: slice project funded by most recent, total raised, texts search
   * fundedItemDisplayed: funded project to display
   * totalFundedPage: total paging
   *
   * @param val
   */

  @action.bound onMostRecentFundedChange(val: boolean) {
    this.mostRecentFilter = val
    this.currentPage = 1
  }

  @action.bound onTotalRaisedFundedChange(val: boolean) {
    this.totalRaisedFilter = val
    this.currentPage = 1
  }

  @action.bound onChangeSearchText(textSearch: string) {
    this.textSearch = textSearch
    this.currentPage = 1
  }

  @action.bound searchFundedProject(page: number) {
    this.currentPage = page
  }

  @computed get slicedFundedProject() {
    let fundedProjects: PoolStore[] = this.fundedProjects

    if (this.mostRecentFilter && this.totalRaisedFilter) {
      //
    }

    if (this.mostRecentFilter && !this.totalRaisedFilter) {
      fundedProjects = sortBy(fundedProjects, (funded) => moment(funded.pool.data?.whitelistConfig?.claimStart))
    }

    if (!this.mostRecentFilter && this.totalRaisedFilter) {
      fundedProjects = sortBy(fundedProjects, (funded) => toNumber(funded.pool.totalRaise))
    }

    return fundedProjects
  }

  @computed get fundedItemDisplayed() {
    return this.slicedFundedProject.slice((this.currentPage - 1) * this.numberItemDisplay, this.currentPage * 5)
  }

  @computed get totalFundedPage() {
    return Math.ceil(this.slicedFundedProject.length / this.numberItemDisplay)
  }
}
